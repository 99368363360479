.game{ 
    position: fixed;
    width: 100vw; 
    height: 100vh;

    .square{ 
        position: fixed;
        width: 80px;
        height: 80px;
        background-color: black;
    
        // min-width: 80px;
        // min-height: 80px;

    }






    .left{ 
        position: fixed;
        left: 0;
        width: 30px;
        height: 100vh;
        background-color: black;
        animation: side 1s;
       
    }
    .right{ 
        position: fixed;
        right: 0;
        width: 30px;
        height: 100vh;
        background-color: black;
        animation: side 1s;
    }
    .bottom{ 
        position: fixed;
        bottom: 0;
        width: 100vw;
        height: 30px;
        background-color: black;
        animation: vert 1s;
    }
    .top{ 
        position: fixed;
        top: 0;
        width: 100vw;
        height:70px;
        background-color: black;
        animation: vert 1s;
        h1{ 
            text-align: center;
            animation: fadeIn 1s steps(1);
        }
    }
}
@keyframes side{ 
    from{width: 0}
}
@keyframes vert{ 
    from{height: 0}
}
@keyframes fadeIn{ 
    from{ opacity: 0;}
    to{ opacity: 1;}
}