@import '../../styling/vars.scss';
@import '../../pages/home/animations.scss';
@import '../../pages/home/gameAnimations.scss';
$topLocation: calc(60px + #{$homeMargin}) ;
$rightLocation: 210px; 
$startingWait : 1.8s; 

@function getMargin($ratio){
  @return calc((#{$outerWidth} - #{$outerWidth} * #{$ratio})/2)
};
@function getWidth($ratio){
  @return calc(#{$outerWidth}  * #{$ratio})
};
@function getMaxWidth($ratio){
  @return calc(200px  * #{$ratio})
};

@function getLocation($location, $ratio){
  @return calc(#{$location} - #{$outerWidth}/2 )
  
};
//calc( #{$location} + #{getMargin($ratio)})

.movingCircle{
// margin-top: $homeMargin;
// padding: calc(- #{$outerWidth}/2);
// margin:  calc(- #{$outerWidth}/2);
position: relative;

  animation: movingCircle 3s  linear 500ms, appear 600ms;


// z-index: 0;
right: -620px;
top: -100px;
// right: calc(100% + 40px) ;   
// margin: auto calc(#{$outerWidth}/ -2);


// float: right;
// margin-left: auto;
// margin-right: calc((#{$outerWidth} * 1.42 ));
// margin-top:calc( #{$outerWidth} / -2);
&.start{
opacity: 0;

  animation: glitchingCircle 500ms $startingWait steps(7), stay 2s;
}
img{  z-index:3;
  position: absolute;}

.middle{

  width: getWidth(0.854);
  margin:  getMargin(0.854);
  // top: getLocation($topLocation, 0.854);
  // right: getLocation($rightLocation, 0.854);
  animation: rotating 3s infinite, fadeIn 700ms;
  &.start{
    animation: glitchMiddle 500ms $startingWait steps(3);
  }
}
.appear{ 
  animation: appear 1s ;
}
.center{
  width: getWidth(0.554); 
  margin:  getMargin(0.554);

  animation: rotating 4s infinite reverse , appear 3.3s;
  &.start{
    animation: glitchCenter 500ms  $startingWait steps(3);
  }
}
.outer{ 

  width: #{$outerWidth};
  animation: rotating 6s infinite, fadeIn 700ms ;
  &.start{
  }
}
svg{
  width: fit-content;
  margin: 0 auto;
  position: absolute;
}

}

@keyframes rotating{ 
  from{ transform: rotate(0);}
  70%{transform: rotate(500deg);}
  to{ transform: rotate(360deg);}
}


@keyframes appear {
   from{ 
     opacity: 0;
   }
   98%{ opacity: 0;}
   to{opacity: 1;}
}


@keyframes movingCircle{ 
  from{


    margin: auto calc(#{$outerWidth}/ -2);
    right: 440px;
    top: -140px;

  }
  40%{
    margin: auto calc(#{$outerWidth}/ -2);
    right: 440px;
    top: -140px;
  }
  50%{
    // margin: 0;
    margin: auto calc(#{$outerWidth}/ -2);


    top: 80px;
    // right: 180px;
    right: 360px;

  }
  60%{
    margin: auto calc(#{$outerWidth}/ -2);

    top: 80px;
    // right: 180px;
    right: 360px;
  }
  to{
 

  }
}
@keyframes fadeIn{
  from{opacity: 0;}
  80%{opacity: 0;}
  to{opacity: 1;}
}
@keyframes glitchingCircle{
  from{
  right: calc((#{$outerWidth} * 1.42 ));
  top: getLocation(calc(#{$topLocation} + 0px), 0);
}
14%{ right: calc((#{$outerWidth} * 1.42 + 10px ));
  top: getLocation(calc(#{$topLocation} + 4px), 0);}

28%{ right: calc((#{$outerWidth} * 1.42  ));
  top: getLocation(calc(#{$topLocation} - 20px), 0);}
  
42%{ right: calc((#{$outerWidth} * 1.42 + 15px ));
  top: getLocation(calc(#{$topLocation} + 0px), 0);}

56%{ right: calc((#{$outerWidth} * 1.42 - 20px ));
  top: getLocation(calc(#{$topLocation} + 0), 0);}

70%{ right: calc((#{$outerWidth} * 1.42 ));
  top: getLocation(calc(#{$topLocation} + 0), 0);}

84%{ right: calc((#{$outerWidth} * 1.42 +4px ));
  top: getLocation(calc(#{$topLocation} + 10px), 0);}

to{ 
  right: calc((#{$outerWidth} * 1.42 ));
top: getLocation(calc(#{$topLocation} - 30px), 0);
}
}
@keyframes glitchCenter{
from{margin: 0 0 0 0;}
50%{  margin:  getMargin(0.554);
}

to{margin: 30px 10px 50px 20px;}
}
@keyframes glitchMiddle{
  from{margin: 20px -50px -30px 40px;}
  50%{  margin:  getMargin(0.854);
  }
  
  to{margin: -10px -10px ;}
}
@keyframes stay{
  from{opacity: 1;}
  to{opacity: 1;}
}











@media (min-width: 768px) and (max-width: 1024px) {
   $outerWidth : 150px;
@function getMargin($ratio){
  @return calc((#{$outerWidth} - #{$outerWidth} * #{$ratio})/2)
};
@function getWidth($ratio){
  @return calc(#{$outerWidth}  * #{$ratio})
};
@function getMaxWidth($ratio){
  @return calc(200px  * #{$ratio})
};

@function getLocation($location, $ratio){
  @return calc(#{$location} - #{$outerWidth}/2 )
  
};
  .movingCircle{
    position: relative;
    left: 300px;
    top: 0px;
    z-index: 0;

.middle{

  width: getWidth(0.854);
  margin:  getMargin(0.854);
}
.center{
  width: getWidth(0.554); 
  margin:  getMargin(0.554);
}

.outer{ 

  width: #{$outerWidth};
}
  }
  @keyframes movingCircle{ 
    from{
  
  
      // margin: auto calc(#{$outerWidth}/ -2);
      left: -20px;
      top: -120px;
  
    }
    30%{
      // margin: auto calc(#{$outerWidth}/ -2);
      left: -20px;
      top: -120px;
    }
    50%{
      // margin: 0;
      // margin: auto calc(#{$outerWidth}/ -2);
  
  
      top: 300px;
      // left: 180px;
      left: 30px;
  
    }
    60%{
      // margin: auto calc(#{$outerWidth}/ -2);
  
      top: 300px;
      // left: 180px;
      left: 30px;
    }
    to{
   
  
    }
  }

}






@media screen and (max-width: 480px) {
  $outerWidth : 100px;
 @function getMargin($ratio){
   @return calc((#{$outerWidth} - #{$outerWidth} * #{$ratio})/2)
 };
 @function getWidth($ratio){
   @return calc(#{$outerWidth}  * #{$ratio})
 };
 @function getMaxWidth($ratio){
   @return calc(200px  * #{$ratio})
 };
 
 @function getLocation($location, $ratio){
   @return calc(#{$location} - #{$outerWidth}/2 )
   
 };
   .movingCircle{
     left: calc(50% + 120px) ;
     top: -45px;
     z-index: 2;
 
 .middle{
 
   width: getWidth(0.854);
   margin:  getMargin(0.854);
 }
 .center{
   width: getWidth(0.554); 
   margin:  getMargin(0.554);
 }
 
 .outer{ 
 
   width: #{$outerWidth};
 }
   }
   @keyframes movingCircle{ 
     from{
   
   
       // margin: auto calc(#{$outerWidth}/ -2);
       left: -20px;
       top: -465px;
   
     }
     30%{
       // margin: auto calc(#{$outerWidth}/ -2);
       left: -20px;
       top: -465px;
     }
     50%{
    
   
       top: 0px;
       // left: 180px;
       left: 30px;
   
     }
     60%{
   
      top: 0px;
      // left: 180px;
       left: 30px;
     }
     to{
    
   
     }
   }
 
 }