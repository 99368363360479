@keyframes fadeInButton {
    from{opacity: 0;}
    75%{opacity: 0;}
    to{opacity: 1;}
}

@keyframes fadeInAboutMe{ 
    from{ 
        opacity: 0;
    }
    95%{opacity: 0;}
    to{ 
        opacity: 1;
    }
}
@keyframes fadeInFrame {
    from{ 
        opacity: 0;
    }
    98%{opacity: 0;}
    to{ 
        opacity: 1;
    }
}