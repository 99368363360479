
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */

    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    .homePage{ 
      .content-wrapper{ 

        max-width: 700px;
      }
      .aboutMe-section{
        grid-template-columns: 1fr;
       
        .aboutMe{
          display: block;
          padding-top: 0px;
          margin-top: -250px;
          width: 500px;

        }

       

    }
      .section{
        grid-template-columns: 1fr;
      }
      .section-image{
        margin-left: auto;
        margin-right: auto;
        &.creativity{ 
          margin-left: auto;
          margin-right: auto; 
        }
      }
    } 
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    .homePage{ 

    .section{
      grid-template-columns: 1fr;
  } 
  .section-image{
    margin-left: auto;
    margin-right: auto;
  }
}
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media screen and (max-width: 480px) {
    
    /* CSS */
    .homePage{ 
    background-size: 550%;

        .skills{

            .skillsList{ 
                grid-template-columns: 1fr;
                max-width: 480px;
                
            }
        }
        .content-wrapper{ 

            max-width: 440px;
        }
        .selfDistruct{
          margin-top: -50px;
          margin-bottom: 40px;
          width: 60px;
          height: 60px;
          font-size: 14px;
          padding: 3px;
        }
        .video-container{
          width: fit-content;
          margin: 0 auto; 
        }
        .frame-container{
          width: fit-content;
          margin: 0 auto;
          }

        .frame{ 
            width: 350px;
            } 
        .aboutMe-section{
            grid-template-columns: 1fr;
            .aboutMe{
              padding-top: 0px;
              margin-top: -110px;
        animation: fadeInAboutMe 5s;

            }

        }
        .section{
            grid-template-columns: 1fr;
        } 
        .section-title { 
            font: normal normal bold 30px Helvetica;
        }
        .section-paragraph{
            width:100%;
        padding-left: 20px;
        padding-right: 20px;

        }
        .text-section{
            padding-right: 0;
            width: 90%;
        }
        .section-image{
         
            &.creativity{ 
                width: 200px;
                margin-right: 50px;
            }
          
        } 

        .section-subtitle{ 
            font-size: 20px;
        }
    .section-paragraph{
        font-size: 18px;

    }
    }
  
  }