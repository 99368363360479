@import '../../styling/vars.scss';
$invisable : rgba(0,0,0,0); 
@import './animations.scss';

.logo{ 

    display: fixed;
    position: fixed;
    z-index: 55;
    cursor: pointer;

    img{width: 80px;}
    top:14px ;
    left: 100px;
}
.navbar{
    position: fixed;
    background-color: $navColor ;
    width: 100%;
    padding: 0 100px;
    height: $navHieght;
    z-index: 50;
    .burgerMenue{
        display: none;
    }
    &.start{ 
        left:0 ;
        transform: rotate(80deg);
        transform-origin: bottom left;
        top: 100vh;
        animation: brokenNav 3s  1s, startBrokenNav 1s, fallingNav 1s 4s cubic-bezier(0.280, 0.840, 0.420, 1) ; 
    }
    .logo{
       
        display: inline;
        img{
            margin-top: 14px;
            width: 80px;
        }
    }

    ul{
        width: fit-content;
        float: right;
        padding-top: 40px;
        padding-right: 160px;
        margin: 0;
        &.start{ 
            position: absolute;
            top: 100vh;
            animation: fallingLinks 1s 1s cubic-bezier(0.280, 0.840, 0.420, 1);
        }

        li{
         
            display: inline;
            // position: absolute;
           
            margin-right: 40px;
            cursor: pointer;
            &:hover{
                 color: $blue;
            }
            &.active{ 
                color: $blue;
            }
         
        }
   
    }
        
}

@media only screen and (max-width: 600px) {
    $navHieght: 50px;
    .logo{ 
        left:20px;

        img{
            width: 70px;
        }
        
    }
    $menueAnimationTiming: 300ms ;
    .navbar{
        .burgerMenue{
            display: block;
            position: fixed ;
            right: 25px;
            transform-origin: top right;
            div{
                width:40px; 
                height: 3px;
                background-color: $color;
                border-radius: 10px;
                position: fixed;
                top:17px;
                right: 30px;
                // &.close{
                //     animation: removingLine  $menueAnimationTiming steps(1); 
                //     background-color: $invisable;
                // }
                &.one{
                    top:27px;
                    &.close{ 
                    animation: removingLine  $menueAnimationTiming steps(1), shrinkLine  $menueAnimationTiming - 100ms 200ms linear, pauseShrinking 200ms; 
                    background-color: $invisable;
                    width: 0;


                    }
                    &.appear{ 
                        animation:pauseApper $menueAnimationTiming, expandLine  $menueAnimationTiming - 100ms $menueAnimationTiming linear,pauseExpanding $menueAnimationTiming ; 
                        background-color: $color;
                        width: 40px;
                        }
                   
                }
                &.two{
                    top:27px + 15px;
                    &.close{ 
                        animation: removingLine  $menueAnimationTiming steps(1), shrinkLine  $menueAnimationTiming - 100ms  100ms linear, pauseShrinking 100ms; 
                        background-color: $invisable;
                        width: 0;
    
                        }
                        &.appear{ 
                            animation:pauseApper $menueAnimationTiming, expandLine  $menueAnimationTiming - 100ms $menueAnimationTiming + 100ms linear,pauseExpanding $menueAnimationTiming + 100ms ; 
                            background-color: $color;
                            width: 40px;
                            
                            }
                }
                &.three{
                    top:27px + 15px + 15px;
                    &.close{ 
                        animation: removingLine  $menueAnimationTiming steps(1), shrinkLine  $menueAnimationTiming - 100ms   linear; 
                        background-color: $invisable;
                        width: 0;
    
                        }
                        &.appear{ 
                            animation:pauseApper $menueAnimationTiming, expandLine  $menueAnimationTiming - 100ms $menueAnimationTiming + 200ms linear,pauseExpanding $menueAnimationTiming + 200ms ; 
                            background-color: $color;
                            width: 40px;
                            }
                
                }
            }
         
                
            
           .line-x1{
                transform-origin: left center ;
                transform: rotate(45deg);
                // margin-bottom: -3px;
                // margin-top: 20px;
                right: 20px - 4px;
                top:40px - 14px;

                margin-right: 10px;
                &.close{ 
                    animation: removingLine  $menueAnimationTiming steps(1), shrinkLinex1  $menueAnimationTiming - 100ms  150ms linear,  pauseShrinking 150ms ; 
                    background-color: $invisable;
                    width: 0;

                    }
                    &.appear{ 
                        animation:pauseApper $menueAnimationTiming, expandLinex1  $menueAnimationTiming - 100ms  $menueAnimationTiming + 150ms linear,  pauseExpanding $menueAnimationTiming + 150ms ; 
                        background-color: $color;
                        width: 40px;
                    }
           }
           .line-x2{
            top:40px - 14px;
            right: 25px + 6px;

            transform-origin: right center  ;
            transform: rotate(-45deg);
            // margin-bottom: -3px;
            margin-right: 5px;
            &.close{ 
                animation: removingLine  $menueAnimationTiming steps(1), shrinkLinex2  $menueAnimationTiming - 100ms   linear; 
                background-color: $invisable;
                width: 0;

                }
                &.appear{ 
                    animation:pauseApper $menueAnimationTiming, expandLine  $menueAnimationTiming - 100ms $menueAnimationTiming  linear, pauseExpanding $menueAnimationTiming; 
                    background-color: $color;
                    width: 40px;
                }
       }
        }
        padding: 20px;
        height: $navHieght;
        ul{
            display: none;
         
      
        }
    }
    ul.list{
        display: none;
     
        z-index: 9;
        width: 100vw;
        position: fixed;
        top:$navHieght;
        left: 0%;

        background-color: $navColor ;
        padding-right: 20px;
        padding-top: 20px;
        &.displayList{
            display: block;
            animation: displayList 500ms ease-out;
        }
        &.removeList{
            top:-300px;
            display: block; 
            animation: removeList 500ms ease-in ;
        }
        li{
            display: block;
            z-index: -2;
            margin-right: 40px;
            cursor: pointer;
            &:hover{
                 color: $blue;
            }
            &.active{ 
                color: $blue;
            }
            margin: 30px 0 ;
        }
    }
}
