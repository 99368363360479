@import '../movingCircle.scss';
@import '../../../styling/vars.scss';
:export{ 
    homeMargin: $homeMargin;
    outerWidth: $outerWidth; 
}


@function getMargin($ratio){
    @return calc((#{$outerWidth} - #{$outerWidth} * #{$ratio})/2)
  };
  @function getWidth($ratio){
    @return calc(#{$outerWidth}  * #{$ratio})
  };
.skill{
    width: $outerWidth;
    margin: calc(#{$outerWidth}/2) auto; 
    img{
        position: absolute;
        z-index: 1;
    }
    .outer{ 
        width: getWidth(1);
        animation: rotating 7s infinite;
        &.reverse{ 
        animation: rotating 7s reverse infinite;

        }
        &.delay{ 
            animation: rotating 7s 500ms infinite;

        }
    }
    .middle{ 
        width: getWidth(0.854);
        margin:  getMargin(0.854);
        animation: rotating 5s infinite;

        &.reverse{
            animation: rotating 5s reverse infinite;
        }
        &.delay{ 
            animation: rotating 5s 500ms infinite;

        }
    }
    .title{ 
        width: 80%;
        font-size: calc(#{$outerWidth} / 10.5);
        // color: black;
        padding-top: 30%;
        padding-bottom:30%;
        // height: $outerWidth;
        text-align: center;
        margin: 10px auto;
        position: relative;
        z-index: 10;
        .gameDev{ 
            &:hover{ 

                animation: gameDev 200ms steps(3) ;
            }
        }
    }
}

@keyframes gameDev{ 
    from{
        text-align: left;
        color: $blue ;
        margin: 30px 60px 0 0;
    }
    50%{
        text-align: center;
        color: #ff0000;
        margin: 10px auto;
        top: 30px;

     }
    to{ 
        // letter-spacing: 10px;
        // font-family: Verdana, Geneva, Tahoma, sans-serif;
        margin: 0 1000px 0 0 ; 
         color: $blue}

}