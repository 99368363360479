 @keyframes brokenPage{
     from{padding-left: 0;
        margin-left: 0;
    }
     to{

        padding-left: 20px;}
 }
 @keyframes frameFalling{ 
     from{
         margin-top:0;
        }
       
        to{ margin-top: 900px}

 }
 @keyframes stillFrame{ 
     from{ margin-top: 0; }
     to{ margin-top: 0; }
 }
 @keyframes fallingButton{ 
     from{margin-top:0}
     to{margin-top:900px}
 }
 @keyframes fallingAbout{
     from{ transform: rotate(0deg); top:calc(60px + #{$homeMargin}); }
    to{ transform: rotate(0deg); top: calc(900px + #{$homeMargin})}
 }
 @keyframes stillAbout{
    from{
        margin-top: 0;
        
        top:calc(60px + #{$homeMargin}); }
    to{
        margin-top: 100px;

        top:calc(60px + #{$homeMargin}); }

 }
 @keyframes breakingAbout{
     from{transform: rotate(0deg);}
   
     to{transform: rotate(3deg);}
 }
 @keyframes leveledAbout{
    from{transform: rotate(0deg);}
    to{transform: rotate(0deg);}

 }
