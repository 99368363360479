@import './vars.scss'; 

body{
    background-color: $backgroundColor;
    color: $color;
    margin: 0;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    ::-webkit-scrollbar {
      width:1px;
  
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba($color: #000000, $alpha:0); 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $blue; 
      border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
}
.remove{
  display: none;
}
.Typist .Cursor {
  display: inline-block;

  &--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
    @keyframes blink {
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
    }
  }
}