@import '../../styling/vars.scss';
.landingPage{
    &.remove{
        display: block;
        height: auto;

    }
    &.start{ 
        height: auto;

    }
    height: 175vh;
    // padding:  70px 100px;
    width: 100%;
    .mainTitle{
        z-index: 2;
        position: fixed ;
        top: 250px;
        left: 100px;
        width: 855px;
        height: 268px;
        font: Bold 73px Helvetica Neue;
        letter-spacing: 3.4px;
        color: $blue;
        .typing{
            color:$color; 
            font-size: 20px;
            letter-spacing: 0;
            
        }
    }
  
    .drawing{
        
        img{
            position: fixed;

        bottom: -10px;
        right: 30px;
            width: 500px;
            // hegiht 412.8
        }
        #brain{
            fill: none;
            stroke: #5c9bbe;
            width: 140px; 
            position: fixed;
            right: 245px;
            bottom: 350px;
            animation: brain ease-out 1s;
        }
    }
  
}
@keyframes brain{ 
    from{bottom:345px}
    to{            bottom: 350px;
    }
}
/* width */
::-webkit-scrollbar {
    width:1px;
    height: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba($color: #000000, $alpha:0); 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $blue; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

@media only screen and (max-width: 600px) {
  
.landingPage{
    .mainTitle{
        top:130px;
        font-size: 35px;
        letter-spacing: 2px;
        left:40px;
        .typing{
        font-size: 15px;

        }

    }
.drawing{
    img{
        width: 300px;
        //height 247.7
    }
    #brain{ 
        width: 84px; 
        right: 159px;
        bottom: 180px;

    }
}


}

@keyframes brain{ 
    from{bottom:175px}
    to{            bottom: 180px;
    }
}
}

@media only screen and (min-device-width: 600px) and (max-device-width: 800px){

    .landingPage{
    .mainTitle{
        top:170px;
        font-size: 60px;
        letter-spacing: 3px;

    }
}
}