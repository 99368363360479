@keyframes brokenNav{
    from{ transform: rotate(1deg);
        top:0;
    }
     30%{ transform: rotate(1deg)}

     70% {transform: rotate(80deg);}
     78% {transform: rotate(78deg);}

     
    80%{ transform: rotate(80deg);
        top:0;
    }
    to{ transform: rotate(80deg);
        top:0;
    }
}
@keyframes fallingNav { 
    from{ top: 0 }
    to{top: 100vh}
}
@keyframes startBrokenNav{  
    from{ transform: rotate(0deg);
        top:0;
    }
    50%{ transform: rotate(0deg);}
    to{ transform: rotate(1deg);
        top:0;
    }
}
@keyframes fallingLinks{
    from{
        top: 0;

    }
     to{ top: 100vh;}
}
@keyframes displayList{
    from{top: -300px;}

}
@keyframes removeList{
   from{top:$navHieght - 40px }
   to{top: -300px;}

}
@keyframes leftToRight{
   from{width:0}

}
@keyframes removingLine{
   from{
       background-color: $color;

   }
    to{  background-color: $invisable;
   }
}

@keyframes shrinkLine{
   from{width:40px; 
   }
   to{width: 0}
}
@keyframes pauseShrinking{
   from{
       width:40px; 
   }
   to{
       width:40px; 
   }
}

@keyframes shrinkLinex1{
   from{
       width:40px; 
       right: 20px - 4px;
   }
   to{
       width: 0;
       right: 60px - 4px
   }
}
@keyframes shrinkLinex2{
   from{
       width:40px; 
    //    right: 25px;
   }
   to{
       width: 0;
    //    right: 45px
   }
}
@keyframes expandLine {
    from{
        width: 0px;
    } 
    to{
        width:40px; 
    }
}
@keyframes pauseExpanding{
    from{
        width:0px; 
    }
    to{
        width:0px; 
    }
 }
 @keyframes expandLinex1 {
    from{
        width: 0px;
        right: 56px;

    } 
    to{
        width:40px; 
        right: 16px;

    }
}
@keyframes pauseApper{ 
    form{ 
        width: 0;
        background-color: $invisable;
    }
    to{ 
        width: 0;
        background-color: $invisable;
    }
}