$backgroundColor: #242424; 
$color: #D4D4D4; 
// $blue: #37D8CB; 
$blue: #00FFEF;
$navColor: #1C1C1C;
$navHieght: 100px;
$homeMargin: 100vh;
$red: red ;

$outerWidth: 200px;
@media screen and (max-width: 480px){

    $outerWidth: 60px; 
}