

@keyframes d1{
    from{   
    
        top: 250px;
        left: 500px;
    }
    to{  
        top: 200px;
        left: 580px;
    }
}
@keyframes d2{
    from{   

        transform: rotate(0deg);
        left : 120px;
        top: 500px;
    }
    to{  
    
        left : 80px;
        top: 560px;
        transform: rotate(-30deg);

    }
}


@keyframes d3{
    from{   


        left : 280px;
        top: 490px;
        transform: rotate(0deg);
    }
    to{  



        left : 280px;
        top: 550px;
        transform: rotate(-300deg);

    }
}
@keyframes d4{
    from{   
        left : 25%;
        top: 65%;
        left : 400px;
        top: 520px;
        transform: rotate(0deg);
    }
    to{  
        left : 35%;
        top: 70%;
        left : 500px;
        top: 570px;
        transform: rotate(10deg);

    }
}

@keyframes d5{
    from{   


        left : 210px;
        top: 550px;
        transform: rotate(0deg);
    }
    to{  


        left : 210px;
        top: 700px;
        transform: rotate(40deg);

    }
}
@keyframes d6{
    from{   
        bottom: 340px;
        right: 400px;
        transform: rotate(0deg);
    }
    to{  
        bottom: 320px;
        right: 440px;
        transform: rotate(-70deg);

    }
}
@keyframes d7{
    from{   
        bottom: 380px;
        right: 440px;
        transform: rotate(0deg);
    }
    to{  
        bottom: 390px;
        right: 480px;
        transform: rotate(10deg);

    }
}
@keyframes d8{
    from{   
        bottom: 380px;
        right: 480px;
        transform:ß rotate(0deg);
    }
    to{  
        bottom: 400px;
        right: 570px;
        transform: rotate(15deg);

    }
}

@keyframes d9{
    from{   
        bottom: 480px;
        right: 400px;
        transform: rotate(0deg);
    }
  
    to{  
        bottom: 500px;
        right: 480px;
        transform: rotate(1000deg);

    }
}

@keyframes d10{
    from{   
        bottom: 490px;
        right: 420px;
        transform: rotate(0deg);
    }
    to{  
        bottom: 530px;
        right: 440px;
        transform: rotate(-20deg);

    }
}

@keyframes d11{
    from{   
        bottom: 510px;
        right: 320px;
        transform: rotate(0deg);
    }
    to{  
        bottom: 550px;
        right: 335px;
        transform: rotate(20deg);

    }
}
@keyframes d12{
    from{   
        bottom: 510px;
        right: 305px;
        transform: rotate(0deg);
    }
    to{  
        bottom: 530px;
        right: 220px;
        transform: rotate(750deg);

    }
}
@keyframes d13{
    from{   
        bottom: 510px;
        right: 220px;
        transform: rotate(0deg);
    }
    to{  
        bottom: 560px;
        right: 180px;
        transform: rotate(60deg);

    }
}
@keyframes d14{
    from{   
        bottom: 460px;
        right: 190px;
        transform: rotate(60deg);
    }
    to{  
        bottom: 480px;
        right: 120px;
        transform: rotate(120deg);
    }

    
}

@keyframes d15{
    from{   
        bottom: 440px;
        right: 220px;
        transform: rotate(0deg);
    }
    to{  
        bottom: 400px;
        right: 100px;
        transform: rotate(120deg);
    }

    
}
@keyframes d16{
    from{   
        bottom: 410px;
        right: 190px;
        transform: rotate(0deg);
    }
    to{  
        bottom: 370px;
        right: 120px;
        transform: rotate(40deg);
    }

    
}



@media only screen and (max-width: 600px) {



    @keyframes d1{
        from{   
        
            top: 110px;
            left: 210px;
            transform: rotate(-10deg);
        }
        to{  
            
            top: 90px;
            left: 270px;
        }
    }
    @keyframes d2{
        from{   
          
            transform: rotate(0deg);
            left : 40px;
            top: 230px;
    
        }
        to{  
           
            transform: rotate(-30deg);
            left : 20px;
            top: 260px;
        }
    }
    
    
    @keyframes d3{
        from{   
            
            top: 59%;
            transform: rotate(0deg);
            left : 150px;
            top: 240px;
        }
        to{  
    
        
            top: 64%;
            transform: rotate(-300deg);
            left : 150px;
            top: 300px;
    
        }
    }
    @keyframes d4{
        from{   

            transform: rotate(0deg);
            left : 200px;
            top: 230px;
        }
        to{  

            transform: rotate(10deg);
            left : 270px;
            top: 260px;
    
        }
    }
    
    @keyframes d5{
        from{   

            top: 65%;
            transform: rotate(0deg);
            left : 160px;
            top: 240px;
        }
        to{  


            transform: rotate(40deg);
            left : 190px;
            top: 250px;
    
        }
    }












// ----------- drawing ----- 



    @function mobRight($location){
        @return (($location - 30px) / 500px) * 300px + 30px;
    }
    @function mobBottom($location){
        @return ($location/ 350px) * 200px;
    }


    @keyframes d6{
        from{   
            bottom: mobBottom(340px);
            right: mobRight(400px);
            transform: rotate(0deg);
        }
        to{  
            bottom: mobBottom(320px);
            right: mobRight(440px);
            transform: rotate(-70deg);
    
        }
    }
    @keyframes d7{
        from{   
            bottom: mobBottom(380px);
            right: mobRight(440px);
            transform: rotate(0deg);
        }
        to{  
            bottom: mobBottom(390px);
            right: mobRight(480px);
            transform: rotate(10deg);
    
        }
    }
    @keyframes d8{
        from{   
            bottom: mobBottom(380px);
            right: mobRight(480px);
            transform:ß rotate(0deg);
        }
        to{  
            bottom: mobBottom(400px);
            right: mobRight(570px) - 20px;
            transform: rotate(15deg);
    
        }
    }
    
    @keyframes d9{
        from{   
            bottom: mobBottom(480px);
            right: mobRight(400px);
            transform: rotate(0deg);
        }
      
        to{  
            bottom: mobBottom(500px);
            right: mobRight(480px);
            transform: rotate(1000deg);
    
        }
    }
    
    @keyframes d10{
        from{   
            bottom: mobBottom(490px);
            right: mobRight(420px);
            transform: rotate(0deg);
        }
        to{  
            bottom: mobBottom(530px);
            right: mobRight(440px);
            transform: rotate(-20deg);
    
        }
    }
    
    @keyframes d11{
        from{   
            bottom: mobBottom(510px);
            right: mobRight(320px);
            transform: rotate(0deg);
        }
        to{  
            bottom: mobBottom(550px);
            right: mobRight(335px);
            transform: rotate(20deg);
    
        }
    }
    @keyframes d12{
        from{   
            bottom: mobBottom(510px) + 5px;
            right: mobRight(305px) - 20px;
            transform: rotate(0deg);
        }
        to{  
            bottom: mobBottom(530px);
            right: mobRight(220px) - 25px;
            transform: rotate(750deg);
    
        }
    }
    @keyframes d13{
        from{   
            bottom: mobBottom(510px);
            right: mobRight(220px);
            transform: rotate(0deg);
        }
        to{  
            bottom: mobBottom(560px);
            right: mobRight(180px);
            transform: rotate(60deg);
    
        }
    }
    @keyframes d14{
        from{   
            bottom: mobBottom(460px);
            right: mobRight(190px);
            transform: rotate(60deg);
        }
        to{  
            bottom: mobBottom(480px);
            right: mobRight(120px);
            transform: rotate(120deg);
        }
    
        
    }
    
    @keyframes d15{
        from{   
            bottom: mobBottom(440px);
            right: mobRight(220px);
            transform: rotate(0deg);
        }
        to{  
            bottom: mobBottom(400px);
            right: mobRight(100px);
            transform: rotate(120deg);
        }
    
        
    }
    @keyframes d16{
        from{   
            bottom: mobBottom(410px);
            right: mobRight(190px);
            transform: rotate(0deg);
        }
        to{  
            bottom: mobBottom(370px);
            right: mobRight(120px);
            transform: rotate(40deg);
        }
    
        
    }
    
}


@media only screen and (min-device-width: 600px) and (max-device-width: 800px){

@function tabletTop($location) {
    @return $location - 140px;
}

@keyframes d1{
    from{   
    
        top: 250px - 80px;
        left: 500px;
    }
    to{  
        top: 200px - 80px;
        left: 580px;
    }
}
@keyframes d2{
    from{   

        transform: rotate(0deg);
        left : 120px;
        top: tabletTop(500px);
    }
    to{  
    
        left : 80px;
        top: tabletTop(560px);
        transform: rotate(-30deg);

    }
}


@keyframes d3{
    from{   


        left : 280px;
        top: tabletTop(490px);
        transform: rotate(0deg);
    }
    to{  



        left : 280px;
        top: tabletTop(550px);
        transform: rotate(-300deg);

    }
}
@keyframes d4{
    from{   
       
        left : 400px;
        top: tabletTop(520px);
        transform: rotate(0deg);
    }
    to{  
        
        left : 500px;
        top: tabletTop(570px);
        transform: rotate(10deg);

    }
}

@keyframes d5{
    from{   


        left : 210px;
        top: tabletTop(550px);
        transform: rotate(0deg);
    }
    to{  


        left : 210px;
        top: tabletTop(700px);
        transform: rotate(40deg);

    }
}


}