@import '../../styling/vars.scss';

.notFound{ 
    padding-top: $navHieght;
    text-align: center;
    font-family: Helvetica;

    h1{
        color: $blue; 
        margin-top: 100px;
        font-size: 300px;
        font-weight: 400;
        font-family: Helvetica;
        // color:red
        }
    h3{
        color:$color;
        font-size: 30px;
        margin-top: -200px;
        font-weight: 500;

    }
    .four{ 
        position: fixed;
        top: 265px;
        left: calc(50vw - 243px);
        $timing : 220ms ;
        &.nuts1{ 
            animation: nuts1 $timing steps(5) infinite; 
        }
        &.nuts2{ 
            animation: nuts2 $timing steps(5) infinite; 
        }
        &.nuts3{ 
            animation: nuts3 $timing steps(5) infinite; 
        }
        &.nuts4{ 
            animation: nuts4 $timing steps(5) infinite; 
        }
        &.nuts5{ 
            animation: nuts5 $timing steps(5) infinite; 
        }

    }
    .eyes{ 
        position: absolute;
        top: 300px;
        left: calc(50vw - 243px);
        display: grid;
        grid-template-columns: auto auto;
        .eye{
            // display: inline;
            margin-right: 12px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: white;


            .pupil{
                margin-left: 15px ;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: black;
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .notFound{

        h1{
            font-size: 150px;
        }
        .four{ 
            width: 75px;
             left: calc(50vw - 122px);
             top: 181px;

        }
        h3{
            font-size: 25px;
            margin-top: -100px;
        }
    }
}


@keyframes nuts1{ 
    0%{opacity: 1;}
    25%{opacity: 0;}
    50%{opacity: 0;}
    75%{opacity: 0;}
    100%{opacity: 0;}
}
@keyframes nuts2{ 
    0%{opacity: 0;}
    25%{opacity: 1;}
    50%{opacity: 0;}
    75%{opacity: 0;}
    100%{opacity: 0;}
}
@keyframes nuts3{ 
    0%{opacity: 0;}
    25%{opacity: 0;}
    50%{opacity: 1;}
    75%{opacity: 0;}
    100%{opacity: 0;}
}
@keyframes nuts4{ 
    0%{opacity: 0;}
    25%{opacity: 0;}
    50%{opacity: 0;}
    75%{opacity: 1;}
    100%{opacity: 0;}
}
@keyframes nuts5{ 
    0%{opacity: 0;}
    25%{opacity: 0;}
    50%{opacity: 0;}
    75%{opacity: 0;}
    100%{opacity: 1;}
}