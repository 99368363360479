$time: 3s;
.debris {
    svg{
        fill: none;
        stroke: #707070;
        position: fixed;
    }

    #d1{
        top: 200px;
        left: 580px;
         width: 70px;         
         animation: d1 $time ease-out ;
    }
    #d2{
        width: 70px;
        left : 80px;
        top: 560px;
        transform: rotate(-30deg);
        animation: d2 $time ease-out;
    }
    #d3{
        width: 10px;
        left : 280px;
        top: 550px;
        transform: rotate(-300deg);
        animation: d3 $time ease-out;
    }
    #d4{
        width: 90px;
        left : 500px;
        top: 570px;
        transform: rotate(10deg);
        animation: d4 $time ease-out;
    }
    #d5{
        width: 40px;
        left : 210px;
        top: 700px;
        transform: rotate(40deg);
        animation: d5 $time ease-out;
    }

    #d6{
        width: 30px;
        bottom: 320px;
        right: 440px;
        transform: rotate(-70deg);
        animation: d6 $time ease-out;
    }
    #d7{
        width: 32px;
        bottom: 390px;
        right: 480px;
        transform: rotate(10deg);
        animation: d7 $time ease-out;
    }
    #d8{
        width: 50px;
        bottom: 400px;
        right: 570px;
        transform: rotate(15deg);
        animation: d8 $time ease-out;
    }
    #d9{

        bottom: 500px;
        right: 480px;
        transform: rotate(1000deg);
        animation: d9 $time ease-out;
    }
    #d10{

        bottom: 530px;
        right: 440px;
        transform: rotate(-20deg);
        animation: d10 $time ease-out;
    }  
    #d11{
        width: 45px;
        bottom: 550px;
        right: 335px;
        transform: rotate(20deg);

        animation: d11 $time ease-out;
    }
    #d12{
        // width: 45px;
        bottom: 530px;
        right: 220px;
        transform: rotate(750deg);

        animation: d12 $time ease-out;
    }
    #d13{
        width: 45px;
        bottom: 560px;
        right: 180px;
        transform: rotate(60deg);

        animation: d13 $time ease-out;
    }
    #d14{
        width: 55px;
        bottom: 480px;
        right: 120px;
        transform: rotate(120deg);

        animation: d14 $time ease-out;
    }
    #d15{
        // width: 55px;
             bottom: 400px;
        right: 100px;
        transform: rotate(120deg);
        // transform: rotate(120deg);

        animation: d15 $time ease-out;
    }
    #d16{
        // width: 55px;
        bottom: 370px;
        right: 120px;
        transform: rotate(40deg);


        animation: d16 $time ease-out;
    }
}

@function mobRight($location){
    @return (($location - 30px) / 500px) * 300px + 30px;
}
@function mobBottom($location){
    @return ($location/ 350px) * 200px;
}
@function mobWidth($location){
    @return ($location/ 500px) * 300px ;
}
@media only screen and (max-width: 600px) {
.debris{

    #d1{
        top: 90px;
        left: 270px;
         width: 40px;         
    }
    #d2{
        width: 40px;
        left : 20px;
        top: 260px;
     
    }
    #d3{
        width: 8px;
        left : 150px;
        top: 300px;
 
    }
    #d4{
        width: 50px;
        left : 270px;
        top: 260px;
       
    }
    #d5{
        width: 20px;
        left : 190px;
        top: 250px;
       
    }







    //-------------- drawnig ---------------



    #d6{
        width: mobWidth(30px);
        bottom: mobBottom(320px);
        right: mobRight(440px);
    }
    #d7{
        width: mobWidth(32px);
        bottom: mobBottom(390px);
        right: mobRight(480px);
    }
    #d8{
        width: mobWidth(50px);
        bottom: mobBottom(400px);
        right: mobRight(570px) - 20px;
    }
    #d9{

        bottom: mobBottom(500px);
        right: mobRight(480px);

    }
    #d10{
        width: 20px;
        bottom: mobBottom(530px);
        right: mobRight(440px);

    }  
    #d11{
        width: mobWidth(45px);
        bottom: mobBottom(550px);
        right: mobRight(335px);

    }
    #d12{
        // width: mobWidth(45px);
        bottom: mobBottom(530px);
        right: mobRight(220px) - 25px;

    }
    #d13{
        width: mobWidth(45px);
        bottom: mobBottom(560px);
        right: mobRight(180px);

    }
    #d14{
        width: mobWidth(55px);
        bottom: mobBottom(480px);
        right: mobRight(120px);

    }
    #d15{
        // width: mobWidth(55px);
        bottom: mobBottom(400px);
        right: mobRight(100px);

    }
    #d16{
        // width: 55px;
        bottom: mobBottom(370px);
        right: mobRight(120px);

    }
}
}

@media only screen and (min-device-width: 600px) and (max-device-width: 800px){
    @function tabletTop($location) {
        @return $location - 140px;
    }
    .debris {
       
        #d1{
            top: 200px - 80px;
            left: 580px;
             width: 70px;         
        }
        #d2{
            width: 70px;
            left : 80px;
            top: tabletTop(560px);
            transform: rotate(-30deg);
            animation: d2 $time ease-out;
        }
        #d3{
            width: 10px;
            left : 280px;
            top: tabletTop(550px);
            transform: rotate(-300deg);
            animation: d3 $time ease-out;
        }
        #d4{
            width: 90px;
            left : 500px;
            top: tabletTop(570px);
            transform: rotate(10deg);
            animation: d4 $time ease-out;
        }
        #d5{
            width: 40px;
            left : 210px;
            top: tabletTop(700px);
            transform: rotate(40deg);
            animation: d5 $time ease-out;
        }
    }

}
  
@import './animations.scss';