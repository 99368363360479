@import '../../styling/vars.scss';
@import './animations.scss';
@import './gameAnimations.scss';
$frameRightMargin: calc(0);
$gravity :cubic-bezier(0.280, 0.840, 0.420, 1);

:export{
    homeMargin: $homeMargin;
    frameRightMargin : $frameRightMargin
}
.homePage{ 
  
    padding-top: $homeMargin;
    padding-bottom: 10vh;
    background-image: url('../../assets/background.png');
    background-position: 1000px auto 0 auto;
    background-attachment:fixed;
    background-position: center;
    background-size: 155%;
    // height: 1000vh;
    // position: absolute;
    width: 100%;
    // margin-top:$navHieght;

      // margin-top: $homeMargin;
      // z-index: 1;
    &.start{ 
        padding-right: 10px;
        animation: brokenPage 200ms infinite steps(2);  
    }
   .aboutMe-section{
       display: grid;
       grid-template-columns: 40% 60%;
   }
   .video{
       width: 100%;
   }
    .frame{ 
        float: right;
        animation: fadeInFrame 4s;
        width: 620px;
        margin-right: $frameRightMargin; 

        &.start{
            margin-top: 900px;
            animation: frameFalling 2s cubic-bezier(0.280, 0.840, 0.420, 1) 1s, stillFrame 1s;
        }

        // margin: auto 100px auto 0;
      // margin-top: -100px;
        // position: absolute;
        // right: 200px;
        // top: calc(200px + #{$homeMargin});
        // transform-origin: top left;
    }
   .content-wrapper{ 

    max-width: 1200px;
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
   }
    .selfDistruct{
        animation: fadeInButton 1s;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        padding:10px;
        font-size: 18px;
        text-align: left;
        border: none; 
        margin-top: 0;
        border: 2px $red solid;

        // animation: brokenPage 200ms infinite steps(2);  

        // position: absolute;
        // top: calc(100px + #{$homeMargin});
        // left: 115px;
        
        cursor: pointer;
        background-color: $red;
        color: $color;
        &.start{ 
            margin-top: 900px;
            animation: fallingButton 2s $gravity 2s, stillFrame 2s;
        }
        &:hover{ 
            background-color: rgba($color: #000000, $alpha: 0);
           
            border: 2px $color solid;
        }
        &:focus {
            outline: none;
            box-shadow: none;
          }

    }
    .aboutMe{
        // position: absolute5
        // top: calc(320px + #{$homeMargin}); 
        // left : 100px;
        &.start{ 
        //  position: absolute;
        //  top: calc(900px + #{$homeMargin});
        //  left : 0;
         transform-origin: top left;
         transform: rotate(3deg);   
        animation: fallingAbout 2s $gravity 2.5s , stillAbout 2.5s, breakingAbout 200ms 1s, leveledAbout 1s ; 
        }
        animation: fadeInAboutMe 2.2s;
        padding-top: 100px;
  
        margin-bottom: 150px;
        h3{ 
            font-size: 30px;

        }
        p{
            // width: 30%;
            font-size: 20px;
        }
        padding-bottom: 150px;
       
    }
    .skills{
        margin: 0 auto ;
        .topSkills{ 
            // font-size: 30px;
        }
        .skillsList{ 
            display: grid;
            grid-template-columns: 1fr 1fr  1fr ;
            width:100%;
            margin: 0 auto;
            
            
        }
    }
    .section{
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 200px auto;
    }
    .section-title { 
        font: normal normal bold 40px Helvetica;
        color: $blue;
        text-transform: uppercase;
    }
    .text-section{
        padding-right: 40px;
    }
    .section-subtitle{ 
        font-size: 30px;
        margin-bottom: 0;
        padding-left: 20px;
    }
    .section-paragraph{
        max-width: 600px;
        font-size: 20px;
        padding-left: 20px;

    }
    .section-image{
        max-width: 600px;
        margin-right: 0;
        margin-left: auto;
        &.creativity{ 
            width: 300px;
            margin-right: 150px;
        }
        img{
            width: 100%;
           
        }
    }
  }
 

@import './homeResponsive.scss'; 