@import '../../styling/vars.scss'; 
:export{ 
    blue: $blue; 
}
.contactMe{ 
    display: flex;
    padding-top: $navHieght;
    height: calc(100vh - #{$navHieght});

    .contacts{
        margin-top:60px;
        width: 50vw;
        padding:0  100px;
        font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
        h1{
            font-weight: 400;
            font-size: 50px;
            color: $blue;

        }
        h3{
        font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
            font-weight: 400;
            margin-top: -40px;
            font-size: 20px;
        }
        
        .icons{
            margin-top: 30px;
            .icon{ 
                display: inline;
                margin-right: 30px;
            }
        }
       form{
            margin-top: 100px;
            @mixin inputs{ 
                border: none; 
                border: solid 2px $color;
                background-color: rgba(0, 0, 0, 0);
                border-radius: 10px; 
                color: $color;
                font-size: 20px;
                font-family: Helvetica Neue,Helvetica,Arial,sans-serif;


            }
            h4{
                font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                font-weight: 400;
                font-size: 20px;
                margin-bottom: 20px;
                width: 605px;

            }
        .inputs{ 
            display: grid;
            grid-template-columns:auto auto;
            width: fit-content;
        }
           input{ 
            @include  inputs;
            // max-width: 45%;

            width: 250px;
            padding: 5px 20px; 
            height: 30px;
            display: block;
            font-size: 18px;
            &.name{
                margin-right: 20px;
            }
           }    
         
           textarea{
                display: block;
                resize: none ;
                padding: 20px;

                @include  inputs;
                // max-width: 90%;
                // max-width: calc(90% + 20px);
                // width: 100%;
                // max-width: 620px;
                width: 565px;
                margin-top: 20px;
                height: 200px;


           }
           .submit{
            padding: 10px auto ;
            background-color: $blue;
            border: none;
            border-radius: 10px;
            font-weight: 500;
            font-size: 20px;      
            border: 2px $blue solid;
            cursor: pointer;
            margin-top: 20px;
            width:110px; 
            
            &:hover{ 
                background-color: rgba($color: #000000, $alpha: 0);
                color: $color;
                border: 2px $color solid;
            }
            &:focus {
                outline: none;
                box-shadow: none;
              }
           }

       }
    }
    .pic  {
        width: 50vw;
        height: calc(100vh - #{$navHieght});
        background-image: url('../../assets/contactMe.png');
        background-size: cover;
        background-repeat: no-repeat;

    }
    .loading{ 
        width: 30px;
        height: 30px;
        margin: -30px 0 0 auto;

    }
}

@import './responsiveContactme.scss';