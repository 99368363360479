
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    .contactMe{ 
        .pic{display: none;}
        .contacts{
            width:100%;
        }
    }
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    .contactMe{ 
        .pic{display: none;}
    }
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */
    .contactMe{ 
        .pic{display: none;}
        .contacts{
            // width:100%;
            margin-top: -20px;
            width: 100%;
                        padding: 0 20px;
            h1{
                font-size: 40px;
            }
            h3{
            margin-top: -20px;
            font-size: 20px;

            }
            .icons{
                margin-top: 30px;
                // display: flex;
                // justify-content: space-between;
                .icon{ 
                    display: inline;
                    margin-right: 40px;
                }
            }
            form{
                margin-top: 50px;
            .inputs{
                
                display: block;
            }
            h4{
                width: 100%;
            }
            input{
                width:  calc(100% + 22px);
                margin: 0;
                margin-top: 20px;
            }
            textarea{
                padding-top: 10px;
                width: calc(100% - 40px);
            }
        }
    }
    }
  }