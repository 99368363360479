@import '../../styling/vars.scss';

:export{ 
    color: var($color); 
}
.gameOver{
   input:focus{
       outline: none ;
   }
    text-align: center;
    .score{
        font-weight: 500;
        margin-top: 50px;
    }
    #Path_354{ 
        &:hover{
            stroke: red ;
        }
    }
    .initails{
        font-weight: 500;
        margin-top: 50px;
        font-size: 20px;
    }
    .back{ 
        width: 30px;
        position: absolute;
        top: 50px;
        left: 100px;
        cursor: pointer;
        &:hover{ 
            filter: gray;
            filter: grayscale(1);
        }
    }
    .scores{
        margin-top: 100px;

        .topScores{
            font-weight: 500;
            font-size: 20px;
        }
        table{
            width: 500px;
            // background-color: red;
            margin: 0 auto;
            height: 300px;
            th{
                margin: 0 60px;
            }
            tr{
                margin-top: 20px;
            }
        }
    }
    .submitScore{
       
        padding: 10px 20px ;
        background-color: $blue;
        border: none;
        border-radius: 20px;
        font-weight: 500;
        display: block;
        margin: 20px auto 0 auto;
        cursor: pointer;
        border: 2px $blue solid;
        &:focus {
            outline: none;
            box-shadow: none;
          }
          &:hover{ 
            background-color: rgba($color: #000000, $alpha: 0);
            color: $color;
            border: 2px $color solid;
        }
    }
    .playAgian{
        padding: 10px 20px ;
        background-color: $blue;
        border: none;
        border-radius: 20px;
        font-weight: 500;
        font-size: 20px;
        position: absolute;
        top: 80vh;
        right: 100px;
        border: 2px $blue solid;

        cursor: pointer;

        &:hover{ 
            background-color: rgba($color: #000000, $alpha: 0);
            color: $color;
            border: 2px $color solid;
        }
        &:focus {
            outline: none;
            box-shadow: none;
          }
    }
    
    .selfDistruct{
        border-radius: 50%;
        height: 80px;
        width: 80px;
        padding:10px;
        font-size: 18px;
        text-align: left;
        border: none; 
        top: 50px;
        right: 100px;
        position: absolute;
        // animation: brokenPage 200ms infinite steps(2);  

        // position: absolute;
        // top: calc(100px + #{$homeMargin});
        // left: 115px;
        
        cursor: pointer;
        background-color: $red;
        border: 2px $red solid;

        color: $color;
       
        &:hover{ 
            background-color: rgba($color: #000000, $alpha: 0);
           
            border: 2px $color solid;
        }
        &:focus {
            outline: none;
            box-shadow: none;
          }

    }
}