.angryMan{
    position: fixed;
    bottom: 0;
    right: 0;
   width:25%;
   padding: 0 auto;
//    display: none;
   animation: angryMan 1s 500ms ease-out, angryManStill 500ms, angryMan reverse 500ms 9s , angryManStill 1s 9.5s ;
   &.start{ 
       display: block;
   }
   p{
       background-color: black;
       padding:20px;
       width: fit-content;
       border-radius: 10px;
       position: relative;
       top: 40px;
       z-index: 2;
       left: -30%;
   }
    img{
           width: 100%;
       // margin: 0 0 0 auto;
       margin-right: 0;
        transform: rotateY(180deg);
       
    }  
   }

   @keyframes angryMan{
    from{right: -25%;}
    to{right: 0;}
}
@keyframes angryManStill{
   from{right: -25%;}
   to{right: -25%;}
}